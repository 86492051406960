import qs from "qs"
import StoryblokClient from "storyblok-js-client"

const Storyblok = new StoryblokClient({
  accessToken: "2zuM5FT7WibQvAKBN0Adxgtt",
})

const DEFAULT_TTL = 1000 * 60 * 5

export const getStoryblokParams = (query = {}, ttl = DEFAULT_TTL) => {
  const universalQuery =
    typeof window !== "undefined"
      ? qs.parse(window.location.search, { ignoreQueryPrefix: true })
      : query
  const isEditor = Boolean(universalQuery._storyblok)
  const forcePublish = Boolean(universalQuery._storyblok_published)
  const isDev = process.env.NODE_ENV !== "production"
  const version = isEditor ? "draft" : "published"
  const date = new Date()
  const cv =
    isEditor || isDev || forcePublish
      ? date.getTime()
      : new Date(Math.round(date.getTime() / ttl) * ttl).getTime()

  return {
    version,
    cv,
  }
}
export default Storyblok
